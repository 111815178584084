<template>
  <div
    class="full no-expand flex-column"
    style="background-color: #f6f6f6; width: 100%"
  >
    <overlay :show="loading" z-index="3000">
      <div class="wrapper">
        <loading type="spinner" size="30" />
      </div>
    </overlay>

    <div class="navi-container">
      <tabs border v-model="currentIndex">
        <template #nav-left>
          <van-button
            class="top-area"
            icon="plus"
            type="primary"
            @click="addArea"
            >区域</van-button
          >
        </template>
        <tab
          v-for="c in list"
          :key="c.id"
          :title="c.label"
          :title-style="{ 'font-size': '0.55rem' }"
        />
      </tabs>
    </div>

    <div
      v-if="list[currentIndex]"
      class="flex-row no-expand edit-area"
      style="padding: 0.2rem 0.2rem 0.2rem 0.2rem"
    >
      <div style="width: 6rem">
        <van-switch v-model="isEdit" size="0.8rem" />
      </div>
      <van-button
        style="flex: 1; background: #3a8ffc"
        type="info"
        round
        size="mini"
        @click="editArea"
        ><van-icon name="edit"></van-icon>
        {{ list[currentIndex].label }}</van-button
      >
      <div style="width: 0.5rem">
        <div
          @click="closeCode"
          v-if="scancode"
          style="
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 1111;
            color: #ee2e2d;
          "
        >
          <van-icon name="close" size="big"/>
        </div>
        <BarScan v-if="scancode" @ok="getResult" />
      </div>
      <van-button
        style="flex: 1; background: #3a8ffc"
        type="info"
        round
        size="mini"
        :loading="delAreaLoading"
        :disabled="table.length > 0"
        @click="deleteArea"
        ><van-icon name="delete"></van-icon>
        {{ list[currentIndex].label }}</van-button
      >
    </div>
    <div class="no-expand" v-if="table.length > 0">
      <scroll ref="bigScroll">
        <div style="padding: 0.5rem 0">
          <van-grid :gutter="10" :column-num="4" :border="false">
            <van-grid-item
              class="table-grid"
              style="height: 4rem; position: relative"
              v-for="t in table"
              :key="t.id"
            >
              <div class="opt-btn" v-if="isEdit">
                <img
                  src="../../assets/image/scan.png"
                  style="width: 1rem; height: 1rem"
                  @click="clickCode(t)"
                />
                <img
                  src="../../assets/image/edit.png"
                  style="width: 1rem; height: 1rem; margin-left: 0.2rem"
                  @click="editTable(t)"
                />
                <img
                  src="../../assets/image/del.png"
                  style="width: 1rem; height: 1rem; margin-left: 0.2rem"
                  @click="deleteTable(t.id)"
                />
              </div>
              <div @click="viewTable(t)">
                <div style="overflow: hidden; margin-left: 0.1rem">
                  <img
                    v-if="t.is_finish"
                    style="width: 2.4rem"
                    src="../../assets/image/table-a.png"
                  />
                  <img
                    v-if="!t.is_finish"
                    style="width: 2.4rem"
                    src="../../assets/image/table-b.png"
                  />
                </div>
                <div class="flex-row" style="padding: 0.1rem 0 0 0">
                  <div class="storage">
                    <div class="table-name">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </scroll>
    </div>
    <draggable
      :limit="{ y: 50 }"
      :position="{ right: '0.8rem', bottom: '3rem' }"
    >
      <div>
        <van-button
          style="height: 2.5rem; width: 2.5rem"
          class="three-d-shadow-mid"
          round
          @click="addTable"
          icon="plus"
          type="primary"
        />
      </div>
    </draggable>
    <van-dialog
      v-model="show"
      :title="
        (form[currentModel].id ? (isView == true ? '查看' : '编辑') : '新增') +
        formModel[currentModel].title
      "
      show-cancel-button
      :before-close="beforeChange"
      ><van-form :disabled="formLoading" ref="form">
        <van-field
          v-for="(item, index) in formModel[currentModel].model"
          :key="index"
          v-model.trim="form[currentModel][item.name]"
          v-bind="item.props"
        />
        <van-field
          v-if="currentModel === 'table'"
          v-model="form.table.table_mode"
          label="是否单人桌"
          :rules="[{ required: true, message: '请选择是否单人' }]"
        >
          <template #input>
            <van-radio-group
              v-model="form.table.table_mode"
              direction="horizontal"
            >
              <van-radio name="0">否</van-radio>
              <van-radio name="1">是</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="isView === true"
          v-model="form.table.table_mode"
          label="二维码"
        >
          <template #input>
            <van-uploader :max-count="1" v-model="form.table.img_url" />
          </template>
        </van-field>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {
  Dialog,
  Tab,
  Tabs,
  Switch as VanSwitch,
  Field as VanField,
  Form as VanForm,
  Icon as VanIcon,
  Loading,
  Overlay,
  GridItem as VanGridItem,
  Grid as VanGrid,
  Button as VanButton,
  Uploader as VanUploader,
  Radio as VanRadio,
  RadioGroup as VanRadioGroup,
} from "vant";
import {
  selectTableAreaList,
  addTableArea,
  editTableArea,
  deleteTableArea,
  selectTableList,
  addTable,
  editTable,
  deleteTable,
  bindQrcode,
} from "@/api/tableAndArea";
import Scroll from "@/components/scroll";
import BarScan from "@/components/scan";
import Draggable from "@/components/draggable";
import { mapState } from "vuex";
export default {
  name: "MerchantOrder",
  components: {
    VanRadioGroup,
    VanSwitch,
    VanButton,
    VanField,
    VanRadio,
    Overlay,
    VanForm,
    VanIcon,
    Loading,
    Scroll,
    BarScan,
    Draggable,
    VanGridItem,
    VanGrid,
    VanUploader,
    Tab,
    Tabs,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      activeNames: [],
      showHistory: false,
      currentIndex: 0,
      currentPage: 1,
      pageSize: 8,
      totalNum: 0,
      showDetail: false,
      isEdit: false,
      show: false,
      isView: false,
      scancode: false,
      list: [],
      table: [],
      loading: false,
      currentModel: "area",
      delAreaLoading: false,
      formLoading: false,
      formModel: {
        area: {
          title: "区域",
          model: [
            {
              props: {
                label: "区域名称",
                placeholder: "请输入区域名称",
                maxlength: 5,
                rules: [{ required: true, message: "请填写区域名称" }],
              },
              name: "label",
            },
            {
              props: {
                label: "优先级",
                placeholder: "请输入1-999的优先级",
                maxlength: 4,
                type: "digit",
                rules: [
                  {
                    pattern: /^\+?[1-9][0-9]{0,2}$/,
                    message: "请填写1-999的整数",
                  },
                ],
              },
              name: "sort",
            },
          ],
        },
        table: {
          title: "桌台",
          model: [
            {
              props: {
                label: "区域名称",
                readonly: true,
              },
              name: "areaName",
            },
            {
              props: {
                label: "桌台名称",
                placeholder: "请输入桌台名称",
                maxlength: 8,
                rules: [{ required: true, message: "请填写桌台名称" }],
              },
              name: "name",
            },
            {
              props: {
                label: "容纳人数",
                placeholder: "请输入1-99的人数",
                maxlength: 3,
                type: "digit",
                rules: [
                  {
                    pattern: /^\+?[1-9][0-9]?$/,
                    message: "请填写1-99的整数",
                  },
                ],
              },
              name: "number",
            },
            {
              props: {
                label: "排序号",
                placeholder: "请输入1-999的序号",
                maxlength: 3,
                type: "digit",
                rules: [
                  {
                    pattern: /^\+?([1-9][0-9]{0,2})$/,
                    message: "请填写1-999的整数",
                  },
                ],
              },
              name: "s_no",
            },
            {
              props: {
                label: "最低消费",
                placeholder: "选填",
                maxlength: 4,
                type: "digit",
                rules: [
                  {
                    pattern:
                      /^\s*|(?:0\.\d?[1-9]|(?!0)\d{1,10}(?:\.\d?[1-9])?)$/,
                    message: "请输入至多小数点前10位后2位的数字",
                  },
                ],
              },
              name: "min_cost",
            },
          ],
        },
      },
      form: {
        area: {
          id: null,
          mer_id: null,
          label: null,
          sort: null,
          remark: null,
        },
        table: {
          mer_id: null,
          area: null,
          id: null,
          name: null,
          number: null,
          remark: null,
          s_no: null,
          min_cost: null,
          areaName: null,
          table_mode: null,
          img_url: [],
        },
      },
      orders: [],
      currentOrder: null,
      currentOrderShow: false,
      orderLoading: false,
      showMenu: false,
      currentTable: null,
      goods: [],
      didUserAdded: false,
      foodName: "",
    };
  },
  watch: {
    activeNames(now, before) {
      if (now.length > before.length) {
        const index = now[now.length - 1];
        this.selectTable(this.list[index]);
      }
    },
    currentIndex() {
      this.refreshTable();
    },
  },
  computed: {
    searchedGoods() {
      return this.goods.filter(
        (value) => value.name.indexOf(this.foodName) > -1
      );
    },
    ...mapState({
      merId: (state) => state.base.merchantId,
    }),
  },
  mounted() {
    this.selectArea();
  },
  methods: {
    refreshTable(force) {
      if (this.list.length === 0) {
        return;
      }
      const index = this.currentIndex > -1 ? this.currentIndex : 0;
      const areaId = this.list[index]?.id;
      if (this.list[index] && this.list[index].children && !force) {
        this.table = this.list[index].children;
      } else {
        if (!areaId) this.currentIndex = 0;
        this.loading = true;
        selectTableList(this.merId, this.list[index].id)
          .then((res) => {
            this.loading = false;
            this.table = res.data;
            this.$set(this.list[index], "children", res.data);
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("获取桌台列表失败，请稍后再试");
          });
      }
    },
    selectArea() {
      this.loading = true;
      selectTableAreaList(this.merId)
        .then((res) => {
          this.list = res.data;
          this.loading = false;
          this.refreshTable(true);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取区域失败，请稍后再试");
        });
    },
    addArea() {
      this.currentModel = "area";
      Object.keys(this.form.area).forEach((key) => {
        this.form.area[key] = null;
      });
      this.form.area.mer_id = this.merId;
      this.show = true;
    },
    editArea() {
      this.currentModel = "area";
      const a = this.list[this.currentIndex];
      Object.keys(this.form.area).forEach((key) => {
        this.form.area[key] = a[key];
      });
      this.show = true;
    },
    deleteArea() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该区域？",
        })
        .then(() => {
          this.loading = true;
          deleteTableArea(this.list[this.currentIndex].id)
            .then(() => {
              this.loading = false;
              this.selectArea();
            })
            .catch(() => {
              this.loading = false;
              this.$toast.fail("删除区域失败，请稍后再试");
            });
        });
    },
    selectTable(area) {
      this.loading = true;
      selectTableList(this.merId, area.id)
        .then((res) => {
          this.loading = false;
          if (!area.children) {
            this.$set(area, "children", res.data);
          } else {
            area.children = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取桌台失败，请稍后再试");
        });
    },
    addTable() {
      Object.keys(this.form.table).forEach((key) => {
        this.form.table[key] = null;
      });
      this.form.table.mer_id = this.merId;
      this.form.table.areaName = this.list[this.currentIndex].label;
      this.form.table.area = this.list[this.currentIndex].id;
      this.currentModel = "table";
      this.show = true;
    },
    editTable(table) {
      Object.keys(this.form.table).forEach((key) => {
        this.form.table[key] = table[key];
      });
      this.form.table.areaName = this.list[this.currentIndex].label;
      this.currentModel = "table";
      this.form.table.min_cost &&
        (this.form.table.min_cost = this.form.table.min_cost / 100);
      this.show = true;
    },
    viewTable(table) {
      this.isView = true;
      Object.keys(this.form.table).forEach((key) => {
        this.form.table[key] = table[key];
      });
      this.form.table.areaName = this.list[this.currentIndex].label;
      this.form.table.img_url = [{ url: table.img_url, isImage: true }];
      this.currentModel = "table";
      this.form.table.min_cost &&
        (this.form.table.min_cost = this.form.table.min_cost / 100);
      this.show = true;
    },
    deleteTable(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该桌台？",
        })
        .then(() => {
          this.loading = true;
          deleteTable(id)
            .then(() => {
              this.loading = false;
              this.selectTable(this.list[this.currentIndex]);
              this.refreshTable(true);
              this.$toast.success("删除成功");
            })
            .catch(() => {
              this.loading = false;
              this.$toast.fail("删除桌台失败，请稍后再试");
            });
        });
    },
    beforeChange(action, done) {
      if (action === "confirm") {
        if (this.isView) {
          this.isView = false;
          done();
          return;
        }
        this.$refs.form
          .validate()
          .then(() => {
            const data = this.form[this.currentModel];
            this.formLoading = true;
            this.loading = true;
            if (this.currentModel === "area") {
              const method = data.id ? editTableArea : addTableArea;
              method(data)
                .then(() => {
                  this.formLoading = false;
                  this.loading = false;
                  this.selectArea();
                  done();
                })
                .catch(() => {
                  this.formLoading = false;
                  this.loading = false;
                  this.$toast.fail("网络请求失败，请稍后再试");
                  done(false);
                });
            } else {
              const method = data.id ? editTable : addTable;
              data.min_cost && (data.min_cost = data.min_cost * 100);
              method(data)
                .then(() => {
                  this.formLoading = false;
                  this.loading = false;
                  this.selectTable(this.list[this.currentIndex]);
                  this.refreshTable(true);
                  this.$toast.success("修改成功");
                  done();
                })
                .catch(() => {
                  this.formLoading = false;
                  this.loading = false;
                  this.$toast.fail("网络请求失败，请稍后再试");
                  done(false);
                });
            }
          })
          .catch(() => {
            this.isView = false;
            done(false);
          });
      } else {
        this.isView = false;
        done();
      }
    },
    clickCode(tab) {
      this.currentTable = tab;
      this.scancode = true;
    },
    closeCode() {
      this.scancode = false;
    },
    //返回扫描结果并关闭摄像头
    getResult(res) {
      if (res !== "") {
        this.scancode = false;
        this.$dialog
          .confirm({
            title: "",
            message: "确认【" + this.currentTable.name + "】餐桌绑定该二维码？",
          })
          .then(() => {
            //console.log(res.substr(res.lastIndex("=") + 1));
            console.log("tableId" + this.currentTable.name);
            bindQrcode({
              tab_id: this.currentTable.id,
              sid: res.substr(res.lastIndexOf("=") + 1),
            })
              .then(() => {
                this.$toast.success("绑定成功");
              })
              .catch(() => {
                this.$toast.success("绑定失败");
              });
          })
          .catch(() => {
            console.log("我取消了");
          });
      }
    },
    //识别完文本之后，enter触发接口请求
    confirmBindQrcode() {},
  },
};
</script>

<style lang="less" scoped>
.table-area-operation {
  color: @primary-color;
  font-size: 0.7rem;
  padding-top: 0.4rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.table-area {
  /deep/[class*="van-hairline"]::after {
    border: 1px solid #b7b7b7;
    border-radius: 10px;
  }
  /deep/.van-grid-item__content {
    background-color: rgb(245, 245, 245);
    color: #414545;
  }
  /deep/.van-collapse-item__content {
    padding-left: 0;
    padding-right: 0;
  }
  .table-grid {
    /deep/.van-grid-item__content {
      padding: 0;
    }
  }
}
.history-order-item {
  padding: 0.4rem;
  font-size: 0.6rem;
}
.my-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  thead td {
    color: #768181;
    padding-bottom: 0.1rem;
  }
  tbody td {
    padding: 0.05rem 0;
    color: #6d6d6d;
    &:last-child {
      text-align: right;
    }
  }
}

.top-area {
  border-radius: 0.5rem;
  background: @mch-color;
  border: 0.04rem solid @mch-color;
  min-width: 3.5rem;
}
.edit-area {
  padding: 0.2rem;
  background: #dae6f2;
  margin-top: 0.3rem;
  height: 1rem;
  margin-bottom: 0.4rem;
}

.table-box {
  border-radius: 0.3rem;
  padding: 0.3rem;
  background-color: #fff;
  width: 2.5rem;
  height: 3.2rem;
  float: left;
  margin: 0.3rem 0 0.5rem 0.5rem;
  box-shadow: 0 0 0.1rem #9f9f9f;
}
.opt-btn {
  position: absolute;
  right: 0;
  top: -0.3rem;
  z-index: 100;
  height: 1rem;
}
.storage {
  overflow: hidden;
  width: 100%;
  padding: 0;
  .table-name {
    font-size: 0.45rem;
    color: #3d3d3d;
    text-align: center;
    word-break: break-all;
  }
}
/deep/ .van-tabs__line {
  background-color: @mch-color;
  width: 3rem;
}
/deep/ .van-tab--active {
  color: @mch-color;
}
/deep/ .van-button--primary {
  color: #fff;
  background-color: @mch-color;
  border: 0.04rem solid @mch-color;
}
/deep/ .van-button--mini {
  font-size: 0.5rem;
}
/deep/ .van-uploader__preview-delete {
  width: 0rem;
  height: 0rem;
}
/deep/ .van-uploader__preview-delete-icon {
  font-size: 0rem;
}
/deep/ .van-grid-item__content--center {
  border-radius: 0.4rem;
  background-color: #fff;
  box-shadow: 0 0 0.06rem @mch-color;
}
/deep/ .van-tabs__nav--complete {
  padding-left: 0rem;
}
</style>
